$break-small: 767px;
$break-medium: 1025px;
$break-semi: 1200px;
$break-large: 1230px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) {
      @content;
    }
  } @else if $media == semi-medium-screens {
    @media only screen and (min-width: $break-medium) and (max-width: $break-semi - 1) {
      @content;
    }
  } @else if $media == large-medium-screens {
    @media only screen and (min-width: $break-medium + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $media == wide-screens-abv1200 {
    @media only screen and (min-width: $break-semi) {
      @content;
    }
  } @else if $media == all-large-screen {
    @media only screen and (min-width: $break-small) {
      @content;
    }
  }
}
