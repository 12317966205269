// new code
/* Color Pallete */

$red: #b83436;
$red-dark: #812425;
$yellow: #f3c317;
$orange: #ec7923;
$orange-dark: #c8661d;
$blue-lighter-secondory: #255073;
$blue-lighter: #58cbf5;
$blue-light: #189aca;
$blue-dark: #054e87;
$blue-darker: #0d3d64;
$blue: #267fb3;
$green: #61a644;
$black: #000000;
$white: #ffffff;
$grey-lighter: #f1f1f1;
$grey-light: #f2f2f2;
$grey: #e4e3e3;
$grey-dark: #999999;
$grey-secondary: #c6e5f5;
$grey-light-secondory: #797979;
$header-blue: #eaeaea;
/* Font Specific */

$font-stack: 'Roboto', sans-serif;
$font-name: 'Roboto';
// $regular-font-src: url("css/fonts/Roboto/Roboto-Regular.ttf");
// $medium-font-src: url("css/fonts/Roboto/Roboto-Medium.ttf");
// $bold-font-src: url("css/fonts/Roboto/Roboto-Bold.ttf");
// $light-font-src: url("css/fonts/Roboto/Roboto-Light.ttf");
$main-font-size: 0.875rem;
$main-text-color: $black;

// font
$light-font: $white;
$dark-font: $black;
$primary-color: $red;
$primary-link: $orange;
$primary-border: $grey-dark;

// link & button
$navigation-link: $blue-light;
$primary-btn-txt: $white;
$primary-btn: $orange;
$primary-btn-hover: $orange-dark;
$submit-btn: $blue-dark;
$submit-btn-hover: $blue;
$nav-btn: $red;
$nav-btn-hover: $red-dark;
$disabled-btn: #dddddd;

//  border color
$light-border: #99c1d6;

/* Breadcrumb */

$breadcrumb-background: $white;
$breadcrumb-link-normal: $dark-font;
$breadcrumb-link-active: $dark-font;
$breadcrumb-font-size: 14px;

/* Inline Alerts color */

$alert-success-color: $green;
$alert-error-color: $red;
$alert-warning-color: $yellow;
$alert-info-color: $blue;
//end new code

/* Primary */

$primary-blue: #006699;
$primary-blue-light: #84aac8;
$primary-blue-lighter: #c2d5e4;
$primary-light-blue: #249bc7;
$primary-light-blue-light: #91cde3;
$primary-light-blue-lighter: #c8e6f1;
$primary-black: #2b3740;
$primary-black-light: #959b9f;
$primary-black-lighter: #cacdcf;
/* Secondary */

$secondary-gray: #a8bac7;
$secondary-gray-light: #f3f3f4;
$secondary-gray-lighter: #f9f9f9;
/* Others */

$black: #000000;
$white: #ffffff;
$gray-dark: #393939;
$gray-light: #666666;
$gray-lighter: #999999;
$red-dark: #cc0033;
$gray-lighter1: #acb5bc;
$gray-light1: #878e93;
$grey-light3: #bfbfbf;
/* Buttons */

$light-blue-btn: $primary-light-blue;
$light-blue-btn-hover: #50afd2;
$dark-blue-btn: $primary-blue;
$dark-blue-btn-hover: #0a5592;
// $yellow: #EBB700;
/* Links */

$link1: #999999;
$link1-hover: #50afd2;
$link2: #50afd2;
$link2-hover: #50afd2;
$link3-hover: #ff9933;

/* pace - top loading bar */

$barBackgroundColor: #ef5350;

/* Full page loader */

$loading-icon-url: url('img/loader/loading.svg');
/* Switch slider */

$switch-on-color: #7cce63;
$switch-off-color: #ccc;
