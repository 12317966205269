/* You can add global styles to this file, and also import other style files */

@import './mixins.scss';
@import './variables.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';
// @font-face {
//     font-family: $font-name;
//     src: $regular-font-src;
//     font-weight: 400;
// }

// @font-face {
//     font-family: $font-name;
//     src: $regular-font-src;
//     font-weight: normal;
// }

// @font-face {
//     font-family: $font-name;
//     src: $medium-font-src;
//     font-weight: 500;
// }

// @font-face {
//     font-family: $font-name;
//     src: $bold-font-src;
//     font-weight: bold;
// }

// @font-face {
//     font-family: $font-name;
//     src: $light-font-src;
//     font-weight: 300;
// }

.mobile-only {
  display: none;
  @include respond-to(medium-screens) {
    display: block;
  }
  @include respond-to(handhelds) {
    display: block;
  }
}

.desktop-only {
  @include respond-to(medium-screens) {
    display: none !important;
  }
  @include respond-to(handhelds) {
    display: none !important;
  }
}

body {
  background: $light-font;
  background-repeat: no-repeat;
  padding-top: 130px;
  top: 0px !important;
  font-size: $main-font-size;
  font-family: $font-stack !important;
  font-display: swap;
  color: $main-text-color;
  min-width: 320px;
  @include respond-to(semi-medium-screens) {
    padding-top: 170px;
  }
  @include respond-to(medium-screens) {
    padding-top: 152px;
  }
  @include respond-to(handhelds) {
    padding-top: 147px;
  }
}
.modal-footer {
  padding: 0.75rem;
}
/* Curve section */

.curve-mask {
  width: 100%;
  overflow: hidden;
  .curve-up,
  .curve-down {
    position: relative;
    z-index: 10;
    width: 110%;
    margin-left: -5%;
    margin-right: 5%;
    border-radius: 250vh 250vh 0% 0% / 50vh 50vh 0% 0%;
    -moz-border-radius: 250vh 250vh 0% 0% / 50vh 50vh 0% 0%;
    -webkit-border-radius: 250vh 250vh 0% 0% / 50vh 50vh 0% 0%;
    overflow: hidden;
  }
  .curve-down {
    border-radius: 0% 0% 250vh 250vh / 0% 0% 25vh 25vh;
    -moz-border-radius: 0% 0% 250vh 250vh / 0% 0% 25vh 25vh;
    -webkit-border-radius: 0% 0% 250vh 250vh / 0% 0% 25vh 25vh;
  }
}

.page-wrap {
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;
  transition: all 0.3s ease-in;
  @include respond-to(handhelds) {
    padding: 0 8px;
  }
  /*     @include respond-to(medium-screens) {
        padding: 0 8px;
    } */
}

.content-block .intro-txt {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 3rem;
}

.section-head-title {
  font-size: 24px;
  text-align: center;
  padding-bottom: 50px;
  @include respond-to(handhelds) {
    padding-bottom: 10px;
  }
}

.content-block {
  padding: 4rem 0;
}

.shaded-grey {
  background: #fafafa;
}

.shaded-white {
  background: #ffffff;
}

.sidebar-open {
  position: fixed !important;
  overflow: hidden;
  padding-right: 17px;
}

.round-form {
  border-radius: 5px;
  @include respond-to(handhelds) {
    height: 46px;
  }
}

// new code
.primary-btn,
.nav-btn,
.submit-btn {
  height: 36px;
  padding: 5px 30px;
  border-radius: 5px;
  color: $primary-btn-txt !important;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
}

.primary-btn {
  background-color: $primary-btn;
  &:hover {
    background-color: $primary-btn-hover;
    transition: all 0.3s ease-in;
  }
}

.nav-btn {
  background-color: $nav-btn;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: $nav-btn-hover;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  }
}

.submit-btn {
  background-color: $submit-btn;
  &:hover {
    background-color: $submit-btn-hover;
  }
}

.custom-btn {
  height: 40px;
  min-width: 210px;
  background-color: $white;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  color: $dark-font;
  font-size: 14px;
  position: relative;
  padding-left: 18%;
  border: 0px;
  img {
    position: absolute;
    left: 8px;
    top: -12px;
  }
}

.app-outline-btn,
.app-outline-btn-blue {
  background-color: transparent !important;
  height: 36px;
  width: auto;
  font-size: 14px;
  font-weight: bold;
  border-color: $white;
  padding-left: 35px;
  padding-right: 35px;
  color: $white;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
}

.app-outline-btn-blue {
  border: 2px solid $submit-btn;
  color: $submit-btn;
}

// new code end
.bottom-action-section {
  padding: 15px;
  background: $primary-blue;
  font-size: 16px;
  height: 70px;
  .page-wrap {
    display: block;
  }
  .btn {
    font-size: 16px !important;
  }
}

input,
button,
.btn {
  &:focus,
  &:active {
    outline: none !important;
  }
}

input:active {
  border: 2px solid $primary-link;
}

.modal {
  overflow-y: auto;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-footer,
.modal-header {
  height: unset;
}
/* pace - top loading bar */

.pace {
  .pace-progress {
    height: 5px !important;
    background: $barBackgroundColor;
  }
  .pace-progress-inner {
    box-shadow: 0 0 10px $barBackgroundColor, 0 0 5px $barBackgroundColor;
  }
  .pace-activity {
    border-top-color: $barBackgroundColor;
    border-left-color: $barBackgroundColor;
  }
}

.light-text {
  color: $gray-light;
}

.to-right {
  margin-left: auto;
}

.primary-link,
.navigate-link {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}
.primary-link {
  color: $primary-link !important;
  @include respond-to(handhelds) {
    font-size: 16px;
  }
}

.navigate-link {
  color: $navigation-link;
}

.final-success-alert {
  background-color: $alert-success-color;
}

.final-error-alert {
  background-color: $alert-error-color;
}

.cursor {
  cursor: pointer;
}

.vertical-center {
  display: grid;
  justify-content: center;
  align-content: center;
}

/* Loading screen */

.loading {
  position: fixed;
  z-index: 999;
  height: 5em;
  width: 5em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: $loading-icon-url;
  background-size: 100%;
  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Radio Button */

[type='radio'] {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    & + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: $white;
      }
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: $light-blue-btn;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Checkbox Button */

[type='checkbox'] {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    & + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 0%;
        background: $white;
      }
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: $light-blue-btn;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 0%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  /* Hide default HTML checkbox */
  input {
    display: none;
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $switch-off-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  input:checked + .slider {
    background-color: $switch-on-color;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px $switch-on-color;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 15px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

/* Dropdown */

.ng-select .ng-control {
  border-radius: 5px !important;
  @include respond-to(handhelds) {
    height: 46px;
    .ng-value-label {
      display: flex;
      padding-top: 4px;
    }
  }
}

.ng-select.disabled .ng-control {
  background-color: $disabled-btn;
}

.is-invalid.ng-select .ng-control {
  border-color: $red-dark !important;
}

.ng-select-dropdown-outer .ng-select-dropdown .ng-option.marked {
  background-color: $primary-btn !important;
  color: $white !important;
}

.ng-select {
  .ng-arrow-zone {
    text-align: left !important;
    .ng-arrow {
      border: none !important;
      &:after {
        content: '\f078';
        font-family: FontAwesome;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform 0.25s linear;
        -webkit-transition: -webkit-transform 0.25s linear;
        color: #999;
      }
    }
  }
  &.opened .ng-arrow-zone .ng-arrow:after {
    content: '\f077';
  }
  &.disabled .ng-arrow-zone .ng-arrow:after {
    display: none;
  }
}

.hr-line {
  width: 100%;
}

.card-btn-grey {
  background-color: #a5adbb;
  padding: 5px 30px;
  border-radius: 25px;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
  cursor: pointer;
  border: none;
}

.tooltip-container {
  color: #c2c2c2;
  font-size: 20px;
}

.font-weight-medium {
  font-weight: 500;
}

.detail-header {
  .main-category {
    .category-block {
      top: -185px !important;
    }
  }
}

.detail-header {
  .search-zipcode {
    font-size: 14px !important;
  }
}

.hero-banner {
  .search-zipcode {
    width: 260px !important;
    text-align: left !important;
    @include respond-to(handhelds) {
      font-size: 14px !important;
      width: 130px !important;
    }
  }
  .get-started-btn {
    padding: 5px 20px !important;
  }
}

.fixed-header {
  .search-zipcode {
    width: 84px !important;
  }
  .get-started-btn {
    font-size: 12px;
    padding: 5px 5px !important;
  }
}

.detail-header-breadcrum {
  .breadcrumb-wrapper {
    .custom-breadcrumb {
      line-height: 49px !important;
    }
  }
}

.gt-hide {
  display: none;
}

.fixed-nav {
  &.top-bar {
    height: 70px !important;
    @include respond-to(medium-screens) {
      height: 60px !important;
    }
    @include respond-to(handhelds) {
      height: 54px !important;
    }
  }
  .branding {
    a {
      padding-top: 12px !important;
      @include respond-to(medium-screens) {
        padding-top: 8px !important;
      }
      @include respond-to(handhelds) {
        padding-top: 0px !important;
      }
      img {
        height: 35px !important;
      }
    }
    .partner-logo::before {
      height: 50% !important;
      margin-top: 15px !important;
    }
  }
  .secondary-nav {
    ul {
      &.menu {
        padding: 0px 0 0 !important;
      }
    }
  }
  .nav {
    ul {
      .nav-icon {
        a {
          line-height: 68px !important;
          @include respond-to(medium-screens) {
            line-height: 70px !important;
          }
          @include respond-to(handhelds) {
            line-height: 69px !important;
          }
        }
      }
    }
  }
  .modal.fade.show.in {
    @include respond-to(medium-screens) {
      top: 60px !important;
    }
    @include respond-to(handhelds) {
      top: 0px !important;
    }
    top: 70px !important;
  }
}

.modified-breadcrumb .custom-breadcrumb ul li a {
  color: #249bc7 !important;
  text-decoration: underline;
}

.right-panel {
  border: 3px solid rgba(209, 209, 209, 1);
  background-color: rgba(255, 255, 255, 1);
}

.my-auto {
  display: flex;
  align-items: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
}

.description-heading-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 40px;
  color: $dark-font;
  h6,
  p {
    font-size: 14px;
    color: $dark-font;
    font-weight: 400;
  }
  h4,
  h2,
  h1 {
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 40px;
    color: $dark-font;
    font-style: normal !important;
  }
}

.product-detail-body {
  .search-zipcode {
    @include respond-to(medium-screens) {
      width: 130px !important;
    }
    @include respond-to(handhelds) {
      width: 120px !important;
    }
  }
  .search-bar {
    @include respond-to(medium-screens) {
      margin-top: 15px;
      margin-left: 0px !important;
    }
    @include respond-to(handhelds) {
      margin-top: 15px;
    }
  }
  .fa-times {
    @include respond-to(medium-screens) {
      display: none;
    }
    @include respond-to(handhelds) {
      display: none;
    }
  }
  .mobile-serach {
    @include respond-to(medium-screens) {
      display: none;
    }
    @include respond-to(handhelds) {
      display: none;
    }
  }
  .text-visible {
    @include respond-to(medium-screens) {
      display: block !important;
    }
    @include respond-to(handhelds) {
      display: block !important;
    }
  }
  .search {
    .get-started-btn {
      @include respond-to(medium-screens) {
        width: 90px !important;
        font-size: 16px;
      }
      @include respond-to(handhelds) {
        width: 90px !important;
        font-size: 16px;
      }
    }
  }
}

iframe.goog-te-banner-frame {
  display: none;
}

.goog-te-menu2,
.goog-te-menu-frame {
  width: 140px;
  border: none;
  box-shadow: none;
  table {
    width: 120px;
  }
}

.goog-te-menu2-item {
  min-width: 110px;
}

.goog-te-gadget .goog-te-combo,
.goog-te-gadget .goog-te-gadget-simple {
  width: 140px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  background-color: #363d47;
  color: $white;
  border: none;
  border-radius: 3px;
  font-display: swap;
  @include respond-to(medium-screens) {
    font-size: 16px;
    min-width: 155px;
  }
  @include respond-to(handhelds) {
    font-size: 16px;
    min-width: 155px;
  }
  .goog-te-menu-value {
    color: $white;
    font-weight: bold;
    margin: 0px;
    text-decoration: none;
    display: inline-block;
    padding-right: 5px;
    &:after {
      content: '\f107';
      font-family: fontAwesome;
      margin-left: auto;
      font-size: 15px;
      position: absolute;
      top: -2px;
      right: 0px;
      font-weight: 300;
      @include respond-to(medium-screens) {
        right: -3px;
        font-size: 18px;
      }
      @include respond-to(handhelds) {
        right: -3px;
        font-size: 18px;
      }
    }
    span[style] {
      display: none;
    }
  }
  > span {
    vertical-align: top !important;
  }
  a {
    position: relative;
    bottom: 1px;
  }
}

.goog-te-gadget {
  .goog-te-gadget-icon {
    display: none;
  }
  .goog-te-combo:before {
    content: '\f0ac';
    font-family: 'FontAwesome';
    font-size: 15px;
    color: white;
    position: absolute;
    left: 5px;
    z-index: 1;
    position: absolute;
    margin: 0px 5px;
  }
}
.goog-te-gadget * {
  color: white !important;
}
.locationMsg {
  text-align: justify;
  color: $black;
  .bold {
    font-weight: bold;
  }
}

.modal-dialog.custom-error-alert {
  margin-top: 60px;
  .error-modal-custom-header {
    height: 70px !important;
    background-color: #189aca;
    border-radius: 0;
    .info-background {
      height: 100px;
      width: 100px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      z-index: 1;
      border-radius: 50%;
      position: relative;
      bottom: 30px;
    }
    .fa-info-circle {
      color: #189aca;
      font-family: FontAwesome;
      font-size: 60px;
    }
  }
  .info-type {
    background-color: #189aca !important;
    .info-background:after {
      content: '\f05a';
      color: #189aca;
      font-family: FontAwesome;
      font-size: 70px;
      position: relative;
      bottom: 2.5px;
    }
  }
  .tech-type {
    background-color: #eb7924 !important;
    .info-background:after {
      content: '\f013';
      color: #eb7924;
      font-family: FontAwesome;
      font-size: 70px;
      position: relative;
      bottom: 2.5px;
    }
  }
  .print-btn {
    color: $blue-dark;
    padding: 12px 50px;
    border-radius: 5px;
    background-color: #e9e9e9;
    font-size: 16px;
    font-weight: bold;
  }
}

.pwd-toggle {
  font-size: 18px;
  float: right;
  margin-right: 12px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  color: #999;
  padding: 2px;
  background-color: #ffffff;
}

.font-unbounce-title {
  font-size: 32px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  @include respond-to(handhelds) {
    font-size: 24px !important;
  }
}

/* IE10+ CSS styles go here AP March 9 2020*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidebar-open {
    width: 100% !important;
  }
}
/* Microsoft Edge Browser 12+ (All) - @supports method AP March 9 2020 */

@supports (-ms-ime-align: auto) {
  .sidebar-open {
    width: 100% !important;
  }
}

.reveal {
  transform: scale(0);
  opacity: 0;
  transition-duration: 0.5s;
  transition-delay: 0.3s;
}
.reveal.active {
  transform: scale(1);
  opacity: 1;
}
.modal-main-class {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-content {
  display: flex !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border: 1px solid #ddd;
  background-color: unset;
  box-shadow: unset;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: unset;
}

.hide-slider {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.state-arrow {
  .ng-arrow-wrapper {
    .ng-arrow {
      display: none !important;
    }
  }
}

.old-price-text {
  background-color: unset !important;
  span {
    color: #7a7a7a !important;
  }
}
@import url('css/style.css');
